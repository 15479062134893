











import { Component, Vue } from 'vue-property-decorator'
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {AccountFriendsModuleStoreModule} from "@/store";
import {DefaultRedirectRoutes} from "@/router/redirect.routes";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import CircularLoading from "@/components/Loading/CircularLoading.vue";
import { find } from 'lodash'

@Component({
  components: {CircularLoading},
})
export default class FriendInvitationPage extends Vue {
  @Getter(AccountModuleGetterTypes.ACCOUNT, { namespace: StoreNamespace.Account })
  private readonly account!: IAccountEntity

  @Getter(AccountFriendsModuleGetterTypes.INVITED_BY_ACCOUNT, { namespace: StoreNamespace.AccountFriends })
  private readonly invitedByAccount!: IAccountEntity

  @Getter(AccountFriendsModuleGetterTypes.FRIENDS, { namespace: StoreNamespace.AccountFriends })
  private readonly friends!: IAccountEntity[]

  private invitationCode: string | undefined = ''

  private async created() {
    this.invitationCode = this.$route.query.code as string

    if (!this.invitationCode) {
      await this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName())
      return
    }

    if (this.account.invitation_code === this.invitationCode) {
      await this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName())
      return
    }

    if (find(this.friends, (friend: IAccountEntity) => friend.invitation_code === this.invitationCode )) {
      await this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName())
      return
    }

    const setInvitedAccountByInviteCodeResult = await AccountFriendsModuleStoreModule.setInvitedAccountByInviteCode(this.invitationCode)
    if (!setInvitedAccountByInviteCodeResult.isSuccess()) {
      await this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName())
    }

    setTimeout(() => {
      this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName(
          { params: { userId: this.invitedByAccount.id } }
      ))
    }, 2000)
  }
}
